import React from "react";
import Helmet from 'react-helmet'
import Layout from "../components/layout";
import Banner from '../_images/event-banner.jpg';
import Logo from '../_images/event-logo-white.svg';
// import MountainRange from '../_images/mountain-range.svg';
import Map from '../_images/campus-map.jpg';





export default (props) => (
  <React.Fragment>
  <Layout locationInfo={props.location}>
    <Helmet>
      <title>An Evening of Celebration</title>
      <body className="event"/>
      <meta name="robots" content="noindex" />
      <link href="https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700&display=swap" rel="stylesheet"></link>
    </Helmet>
    <section className="banner">
      <div className="animated-text">
        <span className="animated fadeInDown strong">What does it take</span>
        <span className="animated fadeInDown delay-1s">To create a transformative educational experience</span>
        <span className="animated fadeInDown delay-2s">To prepare students for the jobs of tomorrow </span>
        <span className="animated fadeInDown delay-3s">To enrich the communities we serve</span>
        <img className="animated zoomIn delay-4s" src={Logo} alt="It Takes a Titan - An Evening of Celebration" />
      </div>
      <img src={Banner} alt="" className="banner-image" />
    </section>
    {/* <section className="MountainRange">
      <img src={MountainRange} alt="" />
    </section> */}
    <section className="intro">
      <article className="fixed">
        <h1 style={{display: 'none' }}>An Evening of Celebration</h1>
        {/* <hr /> */}
        <p>
        With the support of our Titan family of alumni and friends, champions and benefactors, we are poised to build upon our legacy of providing life-changing opportunity. Join us as we move into the public phase of our comprehensive fundraising campaign for Cal State Fullerton.
        </p>
        <p className="date">
        Saturday, February 29, 2020<br />
        <span>Cal State Fullerton Quad </span>
        </p>
        <div className="row details">
          <div className="col-6">
            <ul className="itinerary">
              <li>5:00 p.m. Welcome Reception</li>
              <li>5:30 p.m. Program</li>
              <li>6:30 p.m. Dinner Reception</li>
            </ul>
          </div>
          <div className="col-6">
            <p>
            <em>Cocktail Attire</em><br />
            <strong>RSVP by February 12, 2020 </strong>
            </p>
          </div>
        </div>

        <h3>When You Arrive</h3><br />

        <h4>Parking:</h4>
        <p>Complimentary valet parking available in front of Dan Black Hall. Enter in Entrance 2 off of Nutwood Blvd.</p>

        <h4>Coat Check:</h4>
        <p>Complimentary and available upon entry to the event.</p>

        <h4>Campus Map:</h4>
        <a target="_blank" href="https://goo.gl/maps/YyzEim4tSYXvbrDC9"><img src={Map} alt="Entrance 2 from Nutwood Ave." /></a><br /><br />

        <p>
        Questions — please contact us at 657-278-3480 or <a href="mailto:csufevents@fullerton.edu">csufevents@fullerton.edu</a>
        </p>
      </article>
    </section>
  </Layout>
  </React.Fragment>
);
